import React, { Suspense } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css';
import "./Website/assets/scss/common.scss";
// import Home from 'Website/Pages/Home/Components/Home';
// import About from 'Website/Pages/About/Components/About';
// import Contact from 'Website/Pages/Contact/Components/Contact';
// import Privacy from 'Website/Pages/Privacy/Components/Privacy';
// import Terms from 'Website/Pages/Terms/Components/Terms';
import Layout from 'Website/Layout/Components/Layout';
import Launching from 'Website/Pages/Launching/Components/Launching';
import { HELP_COMMUNICATE_CHAT_CALLS, HELP_COMMUNICATE_CREATE_SHARE_POST, HELP_COMMUNICATE_EXPLORE_VIEW_POST, HELP_DELETE_ACCOUNT_PATH, HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH, HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH, HELP_SETTINGS_INTEREST, HELP_SETTINGS_INVITE_FRIENDS, HELP_SETTINGS_LANGUAGE, HELP_SETTINGS_LINK_ACCOUNT, HELP_SETTINGS_LOGOUT, HELP_SETTINGS_NOTIFICATION, HELP_SETTINGS_UPDATE_ACCOUNT } from 'Website/Pages/Help/constants';

const Home = React.lazy(() => import('Website/Pages/Home/Components/Home'));
const About = React.lazy(() => import('Website/Pages/About/Components/About'));
const Contact = React.lazy(() => import('Website/Pages/Contact/Components/Contact'));
const Privacy = React.lazy(() => import('Website/Pages/Privacy/Components/Privacy'));
const Terms = React.lazy(() => import('Website/Pages/Terms/Components/Terms'));
const Help = React.lazy(() => import('Website/Pages/Help/Components/Help'));

function App() {
  return (
    <Router>
      <Layout>
        <Suspense fallback={<div className='preload'></div>}>
          <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/terms" element={<Terms />} />
              
              <Route path={HELP_DELETE_ACCOUNT_PATH} element={<Help />} />
              <Route path={HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH} element={<Help />} />
              <Route path={HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH} element={<Help />} />
              <Route path={HELP_COMMUNICATE_CREATE_SHARE_POST} element={<Help />} />
              <Route path={HELP_COMMUNICATE_EXPLORE_VIEW_POST} element={<Help />} />
              <Route path={HELP_COMMUNICATE_CHAT_CALLS} element={<Help />} />

              <Route path={HELP_SETTINGS_UPDATE_ACCOUNT} element={<Help />} />
              <Route path={HELP_SETTINGS_INTEREST} element={<Help />} />
              <Route path={HELP_SETTINGS_NOTIFICATION} element={<Help />} />
              <Route path={HELP_SETTINGS_LANGUAGE} element={<Help />} />
              <Route path={HELP_SETTINGS_LINK_ACCOUNT} element={<Help />} />
              <Route path={HELP_SETTINGS_INVITE_FRIENDS} element={<Help />} />
              <Route path={HELP_SETTINGS_LOGOUT} element={<Help />} />

              <Route path="/launchingSoon" element={<Launching />} />
              <Route path="*" element={<Home />} />

          </Routes>
        </Suspense>
      </Layout>
    </Router>
  );
}

export default App;
