export const id = {
    menu: {
        about: "Tentang",
        privacy: "Privasi",
        terms: "Ketentuan",
        contactUs: "Hubungi kami",
        ENKRIPWeb: "{{APP_NAME}} Web",
        home: "Rumah",
        help: "Membantu",
        helpSubMenu:{
            deleteAccount: "Hapus Akun",
            manageProfile: "Kelola Profil",
            featuresToCommunicate: "Fitur untuk Berkomunikasi",
            profileCreation: "Pembuatan Profil",
            editProfileFeatures: "Edit Fitur Profil",
            createAndSharePost: "Buat dan Bagikan Postingan",
            exploreAndViewPost: "Jelajahi dan Lihat Postingan",
            chatsAndCalls: "Obrolan dan Panggilan",
            updateAccount: "Perbarui Pengaturan Akun Anda",
            interest: "Ubah Minat Feed Beranda Anda",
            notification: "Pengaturan Pemberitahuan",
            language: "Ganti Bahasa",
            linkAccount: "Tautkan Akun dengan Halaman Web",
            inviteFriends: "Undang Teman Anda",
            logout: "Keluar dari ENKRIP",
            settings: "Sesuaikan Pengaturan Anda"
        }
    },
    genericContent:{
        email: "E-mail",
        phoneNumber: "Nomor telepon",
        mailingAddress: "Alamat Surat",
        joinEnkrip: "Bergabunglah dengan {{APP_NAME}}",
        note: "Catatan",
        or: "ATAU"
    },
    pages: {
        home: {
            mainBanner:{
                title: "Terhubung dengan Teman & Keluarga",
                desc: "Tetap terhubung dengan orang-orang yang paling berarti, di mana pun hidup membawa Anda. Ngobrol, panggilan video, dan bagikan momen Anda dengan mudah.",
                cardSection1:{
                    title: "Memberdayakan Masyarakat untuk Membangun Komunitas",
                    desc: "Aplikasi kami didedikasikan untuk mengembangkan ruang yang aman dan inklusif tempat semua orang dapat terhubung, berbagi, dan berkembang."
                },
                cardSection2: {
                    title: "Merasa Seperti Anda Ada di Sana",
                    desc: "Baik melalui teks, suara, atau video, tetaplah dekat dengan orang-orang penting di iOS dan Android."
                },
                cardSection3: {
                    title: "Bagikan Secara Percaya Diri dengan Enkripsi End-to-End",
                    desc: "Berkomunikasi secara terbuka dan aman, mengetahui bahwa percakapan Anda dilindungi oleh enkripsi end-to-end yang terdepan di industri."
                }
            },
            banner1:{
                title: "Jangan Pernah Melewatkan Momen dengan Panggilan Suara dan Video",
                desc: "Dari panggilan grup dengan teman hingga check-in cepat bersama keluarga, nikmati suara dan video sejernih kristal yang membuat Anda merasa seperti berada di ruangan yang sama."
            },
            banner2:{
                title: "Jelajahi Konten yang Menarik",
                desc: "Selami dunia foto, video, dan profil yang disesuaikan dengan minat Anda. Temukan dan terhubung dengan konten baru dengan mudah."
            },
            banner3:{
                title: "Abadikan dan Bagikan Momen Sehari-hari Anda",
                desc: "Dari panggilan grup ke teman sekelas hingga panggilan singkat dengan ibu, serasa berada di ruangan yang sama dengan panggilan suara dan video."
            }
        },
        about: {
            title: "Tentang {{APP_NAME}}",
            para1: "Di {{APP_NAME}}, kami berdedikasi untuk mendekatkan orang-orang dengan memberdayakan mereka untuk membangun komunitas yang dinamis dan aman. Aplikasi kami lebih dari sekadar alat komunikasi—ini adalah platform yang dirancang agar tetap terhubung dengan teman, keluarga, dan individu yang berpikiran sama menjadi mudah dan bermakna.",
            para2: "Dengan komitmen terhadap privasi dan keamanan, {{APP_NAME}} memastikan percakapan dan interaksi Anda tetap pribadi melalui enkripsi end-to-end yang canggih. Baik Anda berbagi momen kehidupan, membuat rencana bersama grup, atau sekadar bertemu seseorang yang spesial, {{APP_NAME}} membuat semuanya menjadi mungkin.",
            para3: "Kami percaya dalam menciptakan ruang yang aman dan inklusif di mana setiap orang dapat terhubung, berbagi, dan berkembang. Tersedia di iOS dan Android, aplikasi kami dirancang untuk membuat Anda merasa seperti berada di sana bersama orang-orang yang paling berarti, di mana pun mereka berada."
        },
        privacy: {
            title: "Kebijakan Privasi {{APP_NAME}}",
            para1: "Selamat datang di {{APP_NAME}}! Privasi Anda sangat penting bagi kami. Kebijakan Privasi ini menjelaskan bagaimana PT. Cahaya Sinergi Sentosa (“kami”) mengumpulkan, menggunakan, mengungkapkan, dan melindungi informasi Anda saat Anda menggunakan aplikasi media sosial dan chat kami, Enkrip (“Aplikasi”). Dengan menggunakan Enkrip, Anda setuju dengan pengumpulan dan penggunaan informasi sesuai dengan kebijakan ini.",
            subtitle1:{
                title: "1. Informasi yang Kami Kumpulkan",
                subtitle1:{
                    title: "1.1 Informasi Pribadi",
                    para1: "Saat Anda mendaftar dan menggunakan Aplikasi kami, kami dapat mengumpulkan informasi pribadi berikut:",
                    para2: {
                        label: "Nama:",
                        desc: "Untuk mengidentifikasi dan mempersonalisasi akun Anda."
                    },
                    para3: {
                        label: "Alamat email:",
                        desc: "Untuk berkomunikasi dengan Anda, mengirimkan pembaruan, dan memverifikasi akun Anda."
                    },
                    para4: {
                        label: "Nomor telepon:",
                        desc: "Untuk menghubungkan Anda dengan kontak Anda dan untuk keperluan keamanan."
                    },
                    para5: {
                        label: "Foto profil:",
                        desc: "Untuk mempersonalisasi akun Anda."
                    },
                    para6: {
                        label: "Kontak:",
                        desc: "Kami dapat mengakses daftar kontak Anda dengan persetujuan Anda untuk membantu Anda terhubung dengan teman-teman Anda yang juga menggunakan {{APP_NAME}}."
                    }
                },
                subtitle2: {
                    title: "1.2 Konten",
                    para1: "Kami mengumpulkan konten yang Anda buat, bagikan, dan komunikasikan melalui Aplikasi, termasuk:",
                    para2:{
                        label: "Foto dan video:",
                        desc: "Konten visual yang Anda unggah atau bagikan."
                    },
                    para3:{
                        label: "Pesan dan riwayat obrolan:",
                        desc: "Komunikasi Anda dengan pengguna lain."
                    },
                    para4:{
                        label: "Komentar dan suka:",
                        desc: "Interaksi Anda dengan konten pengguna lain."
                    },
                },
                subtitle3: {
                    title: "1.3 Informasi Penggunaan",
                    para1: "Kami mengumpulkan informasi tentang interaksi Anda dengan Aplikasi, seperti:",
                    para2:{
                        label: "Tanggal dan waktu akses:",
                        desc: "Untuk melacak penggunaan Aplikasi dan meningkatkan pengalaman pengguna."
                    },
                    para3:{
                        label: "Halaman yang dilihat dan fitur yang digunakan:",
                        desc: "Untuk memahami bagaimana Anda menggunakan Aplikasi dan mengoptimalkan layanan kami."
                    },
                    para4:{
                        label: "Tautan yang diklik:",
                        desc: "Untuk menganalisis preferensi dan minat Anda."
                    },
                    para5:{
                        label: "Alamat IP:",
                        desc: "Untuk mengidentifikasi lokasi umum Anda dan meningkatkan keamanan."
                    },
                    para6:{
                        label: "Informasi perangkat:",
                        desc: "Seperti ID perangkat, sistem operasi, jenis browser, untuk memastikan kompatibilitas dan kinerja optimal."
                    },
                }
            },
            subtitle2:{
                title: "2. Bagaimana Kami Menggunakan Informasi Anda",
                para1: "Kami menggunakan informasi yang kami kumpulkan untuk tujuan berikut:",
                para2:{
                    label: "Untuk menyediakan, memelihara, dan meningkatkan Aplikasi:",
                    desc: "Memastikan Aplikasi berfungsi dengan baik dan memperbaiki bug atau masalah teknis."
                },
                para3:{
                    label: "Untuk mempersonalisasi pengalaman Anda:",
                    desc: "Menyajikan konten dan rekomendasi yang relevan."
                },
                para4:{
                    label: "Untuk berkomunikasi dengan Anda:",
                    desc: "Mengirimkan pembaruan, berita, dan materi promosi terkait {{APP_NAME}}."
                },
                para5:{
                    label: "Untuk menganalisis penggunaan dan tren:",
                    desc: "Memahami bagaimana pengguna berinteraksi dengan Aplikasi dan melakukan penelitian untuk meningkatkan layanan kami."
                },
                para6:{
                    label: "Untuk memastikan keamanan dan mencegah penipuan:",
                    desc: "Melindungi pengguna kami dan menjaga integritas Aplikasi."
                }
            },
            subtitle3:{
                title: "3. Berbagi Informasi Anda",
                para1: "Kami dapat berbagi informasi Anda dengan pihak ketiga dalam keadaan berikut:",
                para2:{
                    label: "Dengan persetujuan Anda:",
                    desc: "Kami akan meminta persetujuan Anda sebelum berbagi informasi pribadi Anda dengan pihak ketiga."
                },
                para3:{
                    label: "Dengan penyedia layanan:",
                    desc: "Kami bekerja dengan penyedia layanan yang membantu kami dalam operasi Aplikasi, seperti penyimpanan cloud, analitik, dan dukungan teknis."
                },
                para4:{
                    label: "Ketika diwajibkan oleh hukum:",
                    desc: "Kami dapat mengungkapkan informasi Anda jika diwajibkan oleh hukum atau jika kami percaya bahwa pengungkapan tersebut diperlukan untuk melindungi hak kami atau untuk mematuhi proses hukum."
                },
                para5:{
                    label: "Sehubungan dengan transaksi bisnis:",
                    desc: "Dalam hal merger, akuisisi, atau penjualan aset, informasi Anda dapat ditransfer sebagai bagian dari transaksi tersebut."
                },
            },
            subtitle4:{
                title: "4. Keamanan Data",
                para1: "Kami menerapkan langkah-langkah keamanan yang wajar untuk melindungi informasi Anda dari akses, perubahan, pengungkapan, atau penghancuran yang tidak sah. Namun, tidak ada metode transmisi melalui internet atau penyimpanan elektronik yang sepenuhnya aman, dan kami tidak dapat menjamin keamanan mutlak informasi Anda.",
            },
            subtitle5:{
                title: "5. Hak Anda",
                para1: "Anda memiliki hak-hak berikut terkait dengan informasi pribadi Anda:",
                para2:{
                    label: "Akses:",
                    desc: "Anda dapat meminta salinan informasi pribadi Anda yang kami simpan."
                },
                para3:{
                    label: "Koreksi:",
                    desc: "Anda dapat meminta koreksi atas informasi yang tidak akurat atau tidak lengkap."
                },
                para4:{
                    label: "Penghapusan:",
                    desc: "Anda dapat meminta penghapusan informasi pribadi Anda dalam keadaan tertentu."
                },
                para5:{
                    label: "Pembatasan Pemrosesan:",
                    desc: "Anda dapat meminta pembatasan pemrosesan informasi pribadi Anda dalam keadaan tertentu."
                },
                para6:{
                    label: "Portabilitas Data:",
                    desc: "Anda dapat meminta salinan informasi pribadi Anda dalam format yang dapat dibaca mesin dan umum digunakan."
                },
                para7:{
                    label: "Keberatan:",
                    desc: "Anda dapat mengajukan keberatan terhadap pemrosesan informasi pribadi Anda dalam keadaan tertentu."
                }
            },
            subtitle6:{
                title: "6. Privasi Anak-anak",
                para1: "Aplikasi kami tidak ditujukan untuk anak-anak di bawah usia 13 tahun. Kami tidak secara sengaja mengumpulkan informasi pribadi dari anak-anak di bawah 13 tahun. Jika kami mengetahui bahwa kami telah mengumpulkan informasi pribadi dari anak di bawah 13 tahun, kami akan mengambil langkah-langkah untuk menghapus informasi tersebut.",
            },
            subtitle7:{
                title: "7. Cookies dan Teknologi Pelacakan Lainnya",
                para1: "Kami menggunakan cookies dan teknologi pelacakan serupa untuk mengumpulkan informasi penggunaan dan meningkatkan pengalaman pengguna. Cookies adalah file kecil yang disimpan di perangkat Anda yang membantu kami mengenali Anda dan mengingat preferensi Anda. Anda dapat mengatur browser Anda untuk menolak semua cookies atau untuk memberi tahu Anda ketika cookies dikirimkan. Namun, beberapa fitur Aplikasi mungkin tidak berfungsi dengan baik tanpa cookies.",
            },
            subtitle8:{
                title: "8. Tautan ke Situs Pihak Ketiga",
                para1: "Aplikasi kami mungkin berisi tautan ke situs atau layanan pihak ketiga yang tidak dioperasikan oleh kami. Jika Anda mengklik tautan pihak ketiga, Anda akan diarahkan ke situs pihak ketiga tersebut. Kami sangat menyarankan Anda untuk meninjau Kebijakan Privasi setiap situs yang Anda kunjungi. Kami tidak memiliki kontrol atas dan tidak bertanggung jawab atas konten, kebijakan privasi, atau praktik situs atau layanan pihak ketiga.",
            },
            subtitle9:{
                title: "9. Perubahan pada Kebijakan Privasi Ini",
                para1: "Kami dapat memperbarui Kebijakan Privasi ini dari waktu ke waktu. Kami akan memberi tahu Anda tentang perubahan apa pun dengan memposting Kebijakan Privasi baru di halaman ini. Anda disarankan untuk meninjau Kebijakan Privasi ini secara berkala untuk setiap perubahan. Perubahan pada Kebijakan Privasi ini berlaku efektif ketika diposting di halaman ini.",
            },
            subtitle10:{
                title: "10. Hubungi Kami",
                para1: {
                    desc: "Jika Anda memiliki pertanyaan tentang Kebijakan Privasi ini, silakan hubungi kami di:",
                    email: "Email:",
                    emailId: "{{SUPPORT_EMAIL}}"
                },
                para2: "Dengan menggunakan Enkrip, Anda setuju dengan ketentuan Kebijakan Privasi ini. Jika Anda tidak setuju dengan ketentuan ini, silakan jangan gunakan Aplikasi."
            }
        },
        terms: {
            title: "Ketentuan Penggunaan {{APP_NAME}}",
            subtitle1: {
                title: "Pendahuluan",
                para1: "Selamat datang di {{APP_NAME}}! Ketentuan Penggunaan (“Ketentuan”) ini mengatur penggunaan aplikasi seluler dan web kami (“Aplikasi”). Dengan mengakses atau menggunakan Aplikasi, Anda setuju untuk mematuhi Ketentuan ini. Jika Anda tidak setuju dengan Ketentuan ini, Anda tidak boleh menggunakan Aplikasi ini."
            },
            subtitle2: {
                title: "2. Penerimaan Ketentuan",
                para1: "2.1. Dengan mendaftar, mengakses, atau menggunakan Aplikasi, Anda menerima dan setuju untuk terikat oleh Ketentuan ini dan Kebijakan Privasi kami.",
                para2: "2.2. Jika Anda menggunakan Aplikasi atas nama organisasi, Anda menyatakan dan menjamin bahwa Anda memiliki wewenang untuk mengikat organisasi tersebut pada Ketentuan ini."
            },
            subtitle3: {
                title: "3. Perubahan Ketentuan",
                para1: "3.1. {{APP_NAME}} berhak untuk memperbarui atau memodifikasi Ketentuan ini kapan saja. Kami akan memberi tahu Anda tentang setiap perubahan dengan memposting Ketentuan baru di situs web dan Aplikasi kami atau melalui email.",
                para2: "3.2. Penggunaan Anda yang berkelanjutan terhadap Aplikasi setelah perubahan tersebut merupakan penerimaan Anda terhadap Ketentuan yang direvisi."
            },
            subtitle4: {
                title: "4. Akun Pengguna",
                para1: "4.1. Untuk mengakses fitur tertentu dari Aplikasi, Anda harus membuat akun. Anda setuju untuk memberikan informasi yang akurat, terkini, dan lengkap selama proses pendaftaran.",
                para2: "4.2. Anda bertanggung jawab untuk menjaga kerahasiaan kredensial akun Anda dan untuk semua aktivitas yang terjadi di bawah akun Anda.",
                para3: "4.3. Anda setuju untuk memberi tahu kami segera tentang setiap penggunaan akun Anda yang tidak sah atau pelanggaran keamanan lainnya."
            },
            subtitle5: {
                title: "5. Kebijakan Privasi",
                para1: "5.1. Kebijakan Privasi kami menjelaskan bagaimana kami mengumpulkan, menggunakan, dan membagikan informasi Anda. Dengan menggunakan Aplikasi, Anda menyetujui pengumpulan dan penggunaan informasi Anda sebagaimana dijelaskan dalam Kebijakan Privasi kami."
            },
            subtitle6: {
                title: "6. Penggunaan Aplikasi",
                para1: "6.1. Anda setuju untuk menggunakan Aplikasi sesuai dengan Ketentuan ini dan semua hukum dan peraturan yang berlaku.",
                para2: "6.2. Anda tidak boleh menggunakan Aplikasi untuk mengirimkan konten yang berbahaya atau ilegal, termasuk tetapi tidak terbatas pada virus, malware, atau konten apa pun yang melanggar hak orang lain."
            },
            subtitle7: {
                title: "7. Konten Pengguna",
                para1: "7.1. Anda tetap memiliki hak atas konten apa pun yang Anda unggah, posting, atau bagikan melalui Aplikasi (“Konten Pengguna”). Dengan mengirimkan Konten Pengguna, Anda memberikan {{APP_NAME}} lisensi non-eksklusif, bebas royalti, dapat dipindah-tangankan di seluruh dunia untuk menggunakan, mereproduksi, mendistribusikan, dan menampilkan konten tersebut sehubungan dengan Aplikasi.",
                para2: "7.2. Anda menyatakan dan menjamin bahwa Anda memiliki atau memiliki hak yang diperlukan untuk mengunggah, memposting, atau membagikan Konten Pengguna dan bahwa Konten Pengguna Anda tidak melanggar hak pihak ketiga atau hukum yang berlaku."
            },
            subtitle8: {
                title: "8. Tindakan yang Dilarang",
                para1: "Anda setuju untuk tidak terlibat dalam aktivitas yang dilarang berikut ini:",
                para2: "Menggunakan Aplikasi untuk tujuan ilegal atau mempromosikan kegiatan ilegal.",
                para3: "Menyamar sebagai orang atau entitas lain atau salah menyatakan afiliasi Anda dengan orang atau entitas.",
                para4: "Mengganggu atau mengganggu keamanan, integritas, atau kinerja Aplikasi.",
                para5: "Mencoba mendapatkan akses tidak sah ke Aplikasi atau sistem atau jaringan terkait."
            },
            subtitle9: {
                title: "9. Kekayaan Intelektual",
                para1: "9.1. Aplikasi dan semua konten serta materi yang termasuk di dalamnya, seperti teks, grafik, logo, gambar, dan perangkat lunak, adalah milik ENKRIP atau pemberi lisensinya dan dilindungi oleh undang-undang kekayaan intelektual.",
                para2: "9.2. Anda diberikan lisensi terbatas, non-eksklusif, tidak dapat dipindahkan, dan dapat dicabut untuk mengakses dan menggunakan Aplikasi untuk keperluan pribadi dan non-komersial.",
                para3: "9.3. Anda tidak boleh mereproduksi, mendistribusikan, memodifikasi, membuat karya turunan, menampilkan secara publik, melakukan secara publik, mempublikasikan ulang, mengunduh, menyimpan, atau mengirimkan materi apa pun di Aplikasi kami, kecuali diizinkan oleh Ketentuan ini."
            },
            subtitle10: {
                title: "10. Pengakhiran",
                para1: "10.1. {{APP_NAME}} berhak untuk mengakhiri atau menangguhkan akses Anda ke Aplikasi kapan saja, tanpa pemberitahuan, untuk alasan apa pun, termasuk jika Anda melanggar Ketentuan ini.",
                para2: "10.2. Setelah pengakhiran, hak Anda untuk menggunakan Aplikasi akan segera berakhir, dan Anda harus menghancurkan semua salinan materi yang diunduh atau diperoleh dari Aplikasi."
            },
            subtitle11: {
                title: "11. Batasan Tanggung Jawab",
                para1: "11.1. {{APP_NAME}} tidak bertanggung jawab atas kerugian langsung, tidak langsung, insidental, konsekuensial, atau hukuman yang timbul dari penggunaan atau ketidakmampuan Anda untuk menggunakan Aplikasi.",
                para2: "11.2. {{APP_NAME}} tidak memberikan jaminan atau pernyataan tentang keakuratan atau kelengkapan konten Aplikasi atau konten dari situs web apa pun yang terhubung ke Aplikasi."
            },
            subtitle12: {
                title: "12. Ganti Rugi",
                para1: "12.1. Anda setuju untuk mengganti kerugian, membela, dan membebaskan {{APP_NAME}}, afiliasi, pejabat, direktur, karyawan, agen, dan pemberi lisensi dari klaim, tanggung jawab, kerusakan, putusan, penghargaan, kerugian, biaya, pengeluaran, atau biaya yang timbul dari pelanggaran Anda terhadap Ketentuan ini atau penggunaan Anda terhadap Aplikasi."
            },
            subtitle13: {
                title: "13. Hukum yang Berlaku dan Yurisdiksi",
                para1: "13.1. Ketentuan ini akan diatur dan ditafsirkan sesuai dengan hukum Indonesia, tanpa memperhatikan prinsip konflik hukum.",
                para2: "13.2. Setiap gugatan hukum, tindakan, atau proses yang timbul dari atau terkait dengan Ketentuan ini atau Aplikasi harus diajukan secara eksklusif di pengadilan Indonesia."
            },
            subtitle14: {
                title: "14. Lain-lain",
                para1: "14.1. Ketentuan ini merupakan keseluruhan perjanjian antara Anda dan {{APP_NAME}} terkait penggunaan Aplikasi.",
                para2: "14.2. Jika ada ketentuan dari Ketentuan ini yang dianggap tidak sah atau tidak dapat dilaksanakan, ketentuan tersebut akan dihapus dan ketentuan lainnya akan diberlakukan sepenuhnya sesuai hukum.",
                para3: "14.3. Kegagalan {{APP_NAME}} untuk menegakkan hak atau ketentuan apa pun dalam Ketentuan ini tidak akan dianggap sebagai pengabaian hak atau ketentuan tersebut kecuali diakui dan disetujui oleh {{APP_NAME}} secara tertulis."
            },
            subtitle15: {
                title: "15. Informasi Kontak",
                para1: {
                    desc: "15.1. Jika Anda memiliki pertanyaan atau kekhawatiran tentang Ketentuan ini atau Aplikasi, silakan hubungi kami di"
                }
            }
        },
        contactUs: {
            title: "Hubungi kami",
            desc: "Kami di sini untuk membantu! Jika Anda memiliki pertanyaan, masukan, atau memerlukan bantuan, jangan ragu untuk menghubungi kami. Anda dapat menghubungi kami melalui salah satu metode berikut:"
        },
        deleteAccount: {
            title: "Hapus Akun",
            desc: "Di bawah ini adalah langkah-langkah untuk menghapus akun,",
            step1: "Masuk ke akun ENKRIP Anda di Aplikasi Seluler",
            step2: "Buka 'Halaman Profil' dengan mengetuk gambar profil (kiri atas layar) > Pengaturan (kanan atas layar) > Hapus Akun Saya",
            step3: "Pilih alasan dan Klik \"Lanjutkan\"",
            step4: "Masukkan nomor Ponsel dan OTP",
            step5: "Di halaman Hapus Akun, Klik \"Lanjutkan\"",
            step6: "Akun akan segera dihapus setelah tombol \"Hapus\" diklik",
        },
        manageProfile:{
            title: "Kelola Profil Anda",
            profileCreation: {
                title: "Pembuatan Profil",
                signUp:{
                    title: "1.1 Mendaftar dan Membuat Profil",
                    note: "Anda harus berusia di atas 13 tahun untuk mendaftar di Aplikasi ENKRIP.",
                    indonesianUser:{
                        title: "Untuk Mendaftar dan Membuat Profil jika Anda adalah Pengguna Indonesia:",
                        step1: "Instal Aplikasi “Enkrip” dari Google Play store atau Apple App store.",
                        step2: "Luncurkan Aplikasi.",
                        step3: "Masukkan nomor telepon dan klik tombol \"Lanjutkan\".",
                        step4: "Di popup konfirmasi, konfirmasikan nomor telepon dengan mengklik tombol “Lanjutkan”.",
                        step5: "Masukkan kode yang Anda terima melalui panggilan.",
                        step6: "Jika Anda tidak menerima panggilan setelah 60 detik, klik opsi “Verifikasi melalui Whatsapp” dan konfirmasikan bahwa nomor telepon yang dimasukkan sudah terdaftar di WhatsApp dengan mengklik tombol “Lanjutkan” di munculan konfirmasi.",
                        step7: "Masukkan kode yang Anda dapatkan melalui WhatsApp.",
                        step8: "Klik tombol “Verifikasi” setelah kode dimasukkan.",
                        step9: "Di halaman Mari Tetapkan Profil Anda, masukkan nama pengguna dan nama tampilan Anda.",
                        step10: "Klik tombol “Lanjutkan Pendaftaran”.",
                        step11: "Pada halaman Personalisasikan Profil Anda, masukkan Nama Depan, Nama Belakang, ID Email, DOB, dan Jenis Kelamin.",
                        step12: "Memasukkan Bio tidak wajib.",
                        step13: "Klik tombol \"Lanjutkan\".",
                        step14: "Profil akan berhasil dibuat."
                    },
                    nonIndonesianUser:{
                        title: "Untuk Mendaftar dan Membuat Profil jika Anda adalah Pengguna Non-Indonesia:",
                        step1: "Dapatkan tautan undangan teman dari teman Indonesia Anda.",
                        step2: "Lihat halaman web teman undangan.",
                        step3: "Pilih kode negara Anda dan masukkan nomor telepon.",
                        step4: "Klik tombol “Dapatkan OTP” dan masukkan OTP yang diterima melalui SMS. Klik tombol “Buat Kode Undangan”.",
                        step5: "Instal Aplikasi “Enkrip” dari Google Play store atau Apple App store.",
                        step6: "Luncurkan Aplikasi.",
                        step7: "Pilih kode negara Anda.",
                        step8: "Masukkan nomor telepon dan klik tombol \"Lanjutkan\".",
                        step9: "Di popup konfirmasi, konfirmasikan nomor telepon dengan mengklik tombol “Lanjutkan”.",
                        step10: "Masukkan kode undangan di popup Kode Undangan dan klik tombol “Lanjutkan”.",
                        step11: "Masukkan OTP yang diterima melalui SMS.",
                        step12: "Klik tombol “Verifikasi” setelah kode dimasukkan.",
                        step13: "Di halaman Mari Tetapkan Profil Anda, masukkan nama pengguna dan nama tampilan Anda.",
                        step14: "Mengatur foto profil tidak wajib.",
                        step15: "Klik tombol “Lanjutkan Pendaftaran”.",
                        step16: "Pada halaman Personalisasikan Profil Anda, masukkan Nama Depan, Nama Belakang, ID Email, DOB, dan Jenis Kelamin.",
                        step17: "Memasukkan Bio tidak wajib.",
                        step18: "Klik tombol \"Lanjutkan\".",
                        step19: "Profil akan berhasil dibuat."
                    }
                },
                profilePage:{
                    title: "1.2 Halaman Profil",
                    desc: "Luncurkan aplikasi dan masuk ke aplikasi.",
                    yourProfilePage:{
                        title: "Halaman Profil Anda:",
                        step1: "Di halaman Beranda, klik ikon profil yang ditampilkan di sudut kiri atas untuk melihat halaman profil Anda.",
                        step2: "Di halaman Profil Anda:",
                        step3: "Nama Pengguna Profil dan Nama Tampilan akan ditampilkan.",
                        step4: "Postingan yang dibuat menggunakan akun ini akan ditampilkan.",
                        step5: "Edit profil dan opsi Pengaturan akan ditampilkan di halaman ini.",
                        step6: "Bagikan Profil Anda menggunakan tombol “Bagikan Profil”.",
                        step7: "Opsi Temukan Orang menyarankan orang untuk diikuti yang dikenal oleh Anda dan pengikut Anda."
                    },
                    otherUserProfilePage:{
                        title: "Halaman Profil Pengguna Lainnya:",
                        step1: "Di halaman Beranda, klik ikon profil yang ditampilkan di postingan.",
                        step2: "Di halaman Profil pengguna lain:",
                        step3: "Nama Pengguna Profil dan Nama Tampilan akan ditampilkan.",
                        step4: "Postingan yang dibuat menggunakan akun ini akan ditampilkan.",
                        step5: "Opsi Ikuti saja akan ditampilkan ketika Anda berdua tidak saling mengikuti.",
                        step6: "Opsi ikuti kembali akan ditampilkan ketika pengguna mengikuti Anda.",
                        step7: "Opsi Berhenti Ikuti dan Pesan akan ditampilkan ketika Anda berdua saling mengikuti."
                    }
                }
            },
            editProfile: {
                title: "Edit Fitur Profil",
                updateName: {
                    title: "1.1 Perbarui Nama Pengguna dan Nama Tampilan",
                    note: "Nama pengguna harus memiliki minimal 3 huruf. Nama pengguna tidak boleh diawali dengan garis bawah dan tidak boleh memiliki garis bawah yang berurutan.",
                    username: {
                        title: "Perbarui Nama Pengguna:",
                        step1: "Perbarui Nama Pengguna Anda di bidang nama pengguna halaman Edit Profil.",
                        step2: "Klik tombol simpan pada halaman Edit profil.",
                        step3: "Nama pengguna akan diperbarui."
                    },
                    displaName: {
                        title: "Perbarui Nama Tampilan:",
                        step1: "Perbarui Nama tampilan Anda di bidang nama tampilan halaman Edit Profil.",
                        step2: "Klik tombol simpan pada halaman Edit profil.",
                        step3: "Nama tampilan akan diperbarui."
                    }
                },
                updateProfilePic:{
                    title: "1.2 Perbarui atau Hapus Gambar Profil",
                    step1: "Klik opsi Edit gambar di halaman Edit profil.",
                    step2: "Klik opsi “Hapus foto saat ini” untuk menghapus foto profil Anda.",
                    step3: "Klik opsi “Pilih dari galeri” untuk memilih foto profil dari galeri.",
                    step4: "Klik opsi \"Ambil foto\" untuk membuka kamera.",
                    step5: "Klik tombol Simpan untuk memperbarui atau menghapus gambar profil setelah perubahan selesai."
                },
                bioAndWebsite:{
                    title: "1.3 Tambahkan Bio dan Tautan Situs Web",
                    bio:{
                        title: "Tambahkan Biografi:",
                        step1: "Di kolom Edit Bio halaman profil, masukkan deskripsi tentang Anda atau pemikiran Anda.",
                        step2: "Klik tombol simpan untuk memperbarui bio profil Anda."
                    },
                    website:{
                        title: "Tambahkan Tautan Situs Web:",
                        step1: "Di halaman Edit profil, klik tombol panah di kolom link.",
                        step2: "Masukkan URL situs web Anda di bidang Tambahkan URL Anda.",
                        step3: "Masukkan judul situs web Anda di bidang Judul.",
                        step4: "Klik ikon ( \u2714 ).",
                        step5: "Klik tombol Simpan untuk menambahkan link di halaman Profil."
                    }
                }
            }
        },
        settings:{
            title: "Sesuaikan Pengaturan Anda",
            updateAccount:{
                title: "Perbarui Pengaturan Akun Anda",
                step1: "Klik Pengaturan akun di halaman pengaturan.",
                step2: "Di halaman pengaturan Akun,",
                step3: "Nama depan dan nama belakang dapat diedit.",
                step4: "Nomor telepon akan ditampilkan dan tidak dapat diedit.",
                step5: "ID Email, DOB (Tanggal Lahir), dan Jenis Kelamin dapat diedit.",
                step6: "Klik Tombol \"Perbarui\" untuk menyimpan perubahan."
            },
            interest:{
                title: "Ubah Minat Feed Beranda Anda",
                step1: "Klik opsi Minat yang ditampilkan di halaman pengaturan.",
                step2: "Mampu mencentang dan menghapus centang pada topik yang ditampilkan di halaman ini.",
                step3: "Klik tombol “Perbarui” untuk menyimpan perubahan."
            },
            notification:{
                title: "Pengaturan Pemberitahuan",
                step1: "Klik opsi Pemberitahuan push yang ditampilkan di halaman pengaturan.",
                step2: "Pada halaman pemberitahuan push, hentikan pemberitahuan push seperti yang dijelaskan di bawah ini:",
                step3: "Jika Anda ingin menghentikan notifikasi suka postingan saja, klik suka dan pilih kotak centang nonaktif.",
                step4: "Jika Anda ingin menghentikan notifikasi komentar kiriman saja, klik komentar dan pilih kotak centang nonaktif.",
                step5: "Jika Anda ingin menghentikan notifikasi suka saja, klik suka pada komentar dan pilih kotak centang nonaktif.",
                step6: "Jika Anda ingin menghentikan semua notifikasi, aktifkan tombol sakelar Jeda Semua di halaman notifikasi push.",
                step7: "Di halaman pemberitahuan push, lihat sendiri pemberitahuan push berikut Anda:",
                step8: "Jika Anda ingin melihat pemberitahuan suka pada kiriman dari pengguna yang Anda ikuti saja, klik suka dan pilih kotak centang dari profil yang saya ikuti.",
                step9: "Jika Anda ingin melihat pemberitahuan komentar kiriman dari pengguna yang Anda ikuti saja, klik komentar dan pilih kotak centang dari profil yang saya ikuti.",
                step10: "Jika Anda ingin melihat komentar seperti pemberitahuan dari pengguna yang Anda ikuti saja, klik komentar suka dan pilih kotak centang dari profil yang saya ikuti.",
                step11: "Di halaman pemberitahuan push, lihat pemberitahuan push semua pengguna:",
                step12: "Jika Anda ingin melihat pemberitahuan suka pada kiriman dari semua orang, klik suka dan pilih kotak centang dari semua orang.",
                step13: "Jika Anda ingin melihat pemberitahuan komentar kiriman dari semua orang, klik komentar dan pilih kotak centang dari semua orang.",
                step14: "Jika Anda ingin melihat pemberitahuan suka pada komentar dari semua orang, klik suka pada komentar dan pilih kotak centang dari semua orang.",
                note: "Untuk melihat notifikasi push, tombol sakelar Jeda Semua di halaman notifikasi push harus dinonaktifkan."
            },
            language:{
                title: "Ganti Bahasa",
                step1: "Klik opsi Bahasa yang ditampilkan di halaman pengaturan.",
                step2: "Pilih Bahasa yang Anda inginkan.",
                step3: "Klik “Ya” di popup konfirmasi untuk mengganti bahasa aplikasi."
            },
            linkAccount: {
                title: "Tautkan Akun Dengan Halaman Web",
                step1: "Untuk menghubungkan akun dengan halaman web, masukkan URL <a> web.enkrip.com </a> di kolom situs web.",
                step2: "Pilih opsi Perangkat Tertaut di halaman pengaturan.",
                step3: "Pindai kode QR yang ditampilkan di situs web.",
                step4: "Akun akan berhasil ditautkan ke halaman web."
            },
            inviteFriends: {
                title: "Undang Teman Anda",
                step1: "Klik opsi Undang Teman yang ditampilkan di halaman pengaturan.",
                step2: "Bagikan link tersebut kepada teman Anda yang bukan orang Indonesia untuk login ke aplikasi ENKRIP."
            },
            logout: {
                title: "Keluar",
                step1: "Pilih opsi Logout yang ditampilkan di halaman pengaturan.",
                step2: "Di popup konfirmasi, pilih Cadangkan Sekarang dan klik tombol cadangan jika Anda ingin mengambil cadangan obrolan dan keluar.",
                step3: "Pada popup konfirmasi, pilih abaikan jika Anda ingin logout tanpa backup."
            }
        },
        communicate: {
            title: "Fitur untuk Berkomunikasi",
            post: {
                title: "Buat dan Bagikan Postingan",
                createPost: {
                    title: "Buat Postingan:",
                    step1: "Klik ikon (+) yang ditampilkan di tab footer.",
                    step2: "Pilih opsi Ambil Foto jika Anda ingin membuka kamera.",
                    step3: "Ambil gambar dan masukkan keterangan.",
                    step4: "Klik tombol Posting untuk berhasil memposting.",
                    step5: "Luncurkan aplikasi.",
                    step6: "Klik ikon (+) yang ditampilkan di tab footer.",
                    step7: "Pilih opsi Foto dan Video jika Anda ingin membuka galeri.",
                    step8: "Pilih foto/video/campuran foto dan video dengan batas 10 ke bawah.",
                    step9: "Setelah memilih foto dan video, klik ikon ( \u2714 ).",
                    step10: "Lihat foto dan video yang dipilih, lalu klik lagi ikon ( \u2714 ).",
                    step11: "Masukkan keterangan dan klik tombol Posting untuk berhasil memposting."
                },
                sharePost: {
                    title: "Bagikan Postingan:",
                    step1: "Klik tombol bagikan yang ditampilkan di bawah setiap postingan di dekat tombol komentar.",
                    step2: "Bagikan tautan kiriman dengan teman Anda melalui aplikasi perpesanan mana pun."
                },
                exploreView:{
                    title: "Jelajahi dan Lihat Postingan",
                    step1: "Klik ikon ( <SearchIcon /> ) yang ditampilkan di tab footer.",
                    step2: "Lihat postingan trending yang ditampilkan di halaman Jelajahi.",
                    step3: "Klik kolom pencarian dan masukkan nama akun/caption postingan/tagar yang Anda minta untuk pencarian.",
                    step4: "Klik ikon pencarian setelah teks yang diminta dimasukkan.",
                    step5: "Pada tab For You, akan ditampilkan nama akun dan caption postingan yang mirip dengan teks yang diminta.",
                    step6: "Di tab Akun, nama akun yang mirip dengan teks yang diminta akan ditampilkan.",
                    step7: "Di halaman hashtag, hashtag yang mirip dengan teks yang diminta akan ditampilkan."
                }
            },
            webchat:{
                title: "Obrolan dan Panggilan",
                newChat: {
                    title: "Untuk memulai obrolan baru:",
                    step1: "Klik ikon obrolan yang ditampilkan di halaman footer.",
                    step2: "Klik ikon (+) yang ditampilkan di sudut kanan atas halaman.",
                    step3: "Pilih pengguna yang diminta dari Kontak Saya jika nomor telepon pengguna disimpan dalam buku telepon.",
                    step4: "Pilih pengguna yang diminta dari Teman bersama jika Anda dan pengguna tersebut adalah pengikut bersama."
                },
                chats:{
                    title: "Obrolan :",
                    step1: "Klik ikon obrolan yang ditampilkan di halaman footer.",
                    step2: "Pilih salah satu pengguna.",
                    step3: "Untuk mengirim pesan, masukkan pesan dan klik ikon kirim.",
                    step4: "Untuk mengambil foto/video dan membagikannya, pilih ikon kamera (ATAU) pilih ikon lampiran – Opsi kamera.",
                    step5: "Untuk berbagi foto dan video galeri, pilih ikon lampiran – opsi galeri.",
                    step6: "Untuk membagikan dokumen, pilih ikon lampiran – opsi dokumen.",
                    step7: "Untuk berbagi audio, pilih ikon lampiran – opsi audio.",
                    step8: "Untuk berbagi kontak, pilih ikon lampiran – opsi Kontak.",
                    step9: "Untuk berbagi lokasi, pilih ikon lampiran – opsi lokasi.",
                    step10: "Untuk membagikan gif, pilih ikon gif dan pilih gif yang diminta.",
                    step11: "Untuk membagikan emoji, pilih ikon emoji dan masukkan emoji yang diperlukan untuk dibagikan."
                },
                calls:{
                    title: "Panggilan :",
                    step1: "Klik ikon obrolan yang ditampilkan di halaman footer.",
                    step2: "Pilih salah satu pengguna.",
                    step3: "Di halaman obrolan pengguna, klik ikon panggilan audio untuk panggilan audio.",
                    step4: "Di halaman obrolan pengguna, klik ikon panggilan video untuk panggilan video."
                }
            }
        }
    }
}