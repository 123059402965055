export const HELP_DELETE_ACCOUNT_PATH = '/delete_account';
export const HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH = '/help/manage_profile/create/profile';
export const HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH = '/help/manage_profile/edit/profile';
export const HELP_COMMUNICATE_CREATE_SHARE_POST = '/help/communicate/create/share/post';
export const HELP_COMMUNICATE_EXPLORE_VIEW_POST = '/help/communicate/explore/view/post';
export const HELP_COMMUNICATE_CHAT_CALLS = '/help/communicate/chat/calls';

export const HELP_SETTINGS_INTEREST = '/help/settings/interest';
export const HELP_SETTINGS_INVITE_FRIENDS = '/help/settings/invite/friends';
export const HELP_SETTINGS_LANGUAGE = '/help/settings/language';
export const HELP_SETTINGS_LINK_ACCOUNT = '/help/settings/link/account';
export const HELP_SETTINGS_LOGOUT = '/help/settings/logout';
export const HELP_SETTINGS_NOTIFICATION = '/help/settings/notification';
export const HELP_SETTINGS_UPDATE_ACCOUNT = '/help/settings/update/account';

export const HELP_PAGE_PATHS = [HELP_DELETE_ACCOUNT_PATH,
    HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH,
    HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH,
    HELP_COMMUNICATE_CREATE_SHARE_POST,
    HELP_COMMUNICATE_EXPLORE_VIEW_POST,
    HELP_COMMUNICATE_CHAT_CALLS,
    HELP_SETTINGS_INTEREST,
    HELP_SETTINGS_INVITE_FRIENDS,
    HELP_SETTINGS_LANGUAGE,
    HELP_SETTINGS_LINK_ACCOUNT,
    HELP_SETTINGS_LOGOUT,
    HELP_SETTINGS_NOTIFICATION,
    HELP_SETTINGS_UPDATE_ACCOUNT
];
export const HELP_PAGE_SIDEMENU_TYPES = {
    manageProfile: 'manageProfile',
    settings: 'settings',
    featuresCommunicate: 'featuresCommunicate'
}