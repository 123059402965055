import { NavLink, useLocation } from 'react-router-dom';

const NavigationLink = ({label, paths, labelIcon, ...rest}) => {
    const location = useLocation();

    const classNameFun = ({ isActive }) => {
        let isPathMatch = false;
        if(paths){
            isPathMatch = paths.some(path => location.pathname.startsWith(path));
        }
        
        return (isActive || isPathMatch ? 'active' : '')
    }

    return (
        <NavLink {...rest} className={classNameFun} end>{label} {labelIcon}</NavLink>
    )
}

export default NavigationLink;