const { HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH, HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH, HELP_COMMUNICATE_CHAT_CALLS, HELP_COMMUNICATE_EXPLORE_VIEW_POST, HELP_COMMUNICATE_CREATE_SHARE_POST, HELP_SETTINGS_INTEREST, HELP_SETTINGS_INVITE_FRIENDS, HELP_SETTINGS_LANGUAGE, HELP_SETTINGS_LINK_ACCOUNT, HELP_SETTINGS_LOGOUT, HELP_SETTINGS_NOTIFICATION, HELP_SETTINGS_UPDATE_ACCOUNT, HELP_PAGE_PATHS } = require("./constants");

export const isManageProfilePage = (pathname) => [HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH, HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH].indexOf(pathname) > -1;
export const isCommunicatePage = (pathname) => [HELP_COMMUNICATE_CREATE_SHARE_POST, HELP_COMMUNICATE_EXPLORE_VIEW_POST, HELP_COMMUNICATE_CHAT_CALLS].indexOf(pathname) > -1;

export const isSettingsPage = (pathname) => [
    HELP_SETTINGS_INTEREST,
    HELP_SETTINGS_INVITE_FRIENDS,
    HELP_SETTINGS_LANGUAGE,
    HELP_SETTINGS_LINK_ACCOUNT,
    HELP_SETTINGS_LOGOUT,
    HELP_SETTINGS_NOTIFICATION,
    HELP_SETTINGS_UPDATE_ACCOUNT
].indexOf(pathname) > -1;

export const isHelpPage = (pathname) => HELP_PAGE_PATHS.indexOf(pathname) > -1;