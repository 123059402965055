import { isMobileDevice } from "features/App/helpers";
import { useTranslation } from "react-i18next";
import { MenuDownArrowIcon, MenuUpArrowIcon } from "Website/assets/images";
import { HomeMenu } from "Website/Menu/Components/Menu";
import { HELP_COMMUNICATE_CHAT_CALLS, HELP_COMMUNICATE_CREATE_SHARE_POST, HELP_COMMUNICATE_EXPLORE_VIEW_POST, HELP_DELETE_ACCOUNT_PATH, HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH, HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH, HELP_PAGE_SIDEMENU_TYPES, HELP_SETTINGS_INTEREST, HELP_SETTINGS_INVITE_FRIENDS, HELP_SETTINGS_LANGUAGE, HELP_SETTINGS_LINK_ACCOUNT, HELP_SETTINGS_LOGOUT, HELP_SETTINGS_NOTIFICATION, HELP_SETTINGS_UPDATE_ACCOUNT } from "../constants";
import NavigationLink from "Website/Layout/Components/NavigationLink";
import { useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { isCommunicatePage, isManageProfilePage, isSettingsPage } from "../helpers";

const HelpSidebar = (props) => {
    const isMobile = isMobileDevice(props.deviceType);
    const { t } = useTranslation();
    const location = useLocation();
    const [activeSidemenu, setActiveSidemenu] = useState(null);
    const _isManageProfilePage = isManageProfilePage(location.pathname);
    const _isCommunicatePage = isCommunicatePage(location.pathname);
    const _isSettingsPage = isSettingsPage(location.pathname);

    useEffect(() => {
        if(_isManageProfilePage){
            handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.manageProfile);
        }

        if(_isCommunicatePage){
            handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.featuresCommunicate);
        }

        if(_isSettingsPage){
            handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.settings);
        }
    }, []);

    useEffect(() => {
        if(activeSidemenu && !_isManageProfilePage && !_isCommunicatePage && !_isSettingsPage){
            setActiveSidemenu(null);
        }
    }, [location.pathname]);

    const handleSetActiveSidemenu = (sidemenu, e) => {
        e?.preventDefault();
        const newMenu = isActiveMenu(sidemenu) ? null : sidemenu;
        setActiveSidemenu(newMenu);
    }

    const isActiveMenu = (menu) => activeSidemenu === menu;

    const getSidemenuArrow = (menuActive) => {
        return isActiveMenu(menuActive) ? <MenuUpArrowIcon /> : <MenuDownArrowIcon />;
    }

    return (
        <>
            <ul className={`${isMobile ? "header_nav" : ""}`}>
                {isMobile && <li><HomeMenu t={t} /></li>}
                <li><NavigationLink to={HELP_DELETE_ACCOUNT_PATH} label={t("menu.helpSubMenu.deleteAccount")} /></li>
                <li>
                    <a href="#" onClick={(e) => handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.manageProfile, e)}>
                        {t("menu.helpSubMenu.manageProfile")}
                        <i>
                            {getSidemenuArrow(HELP_PAGE_SIDEMENU_TYPES.manageProfile)}
                        </i>
                    </a>
                    <ul className={`nav-inside ${isActiveMenu(HELP_PAGE_SIDEMENU_TYPES.manageProfile) ? 'show' : ''}`}>
                        <li><NavigationLink to={HELP_MANAGE_PROFILE_CREATE_PROFILE_PATH} label={t("menu.helpSubMenu.profileCreation")} /></li>
                        <li><NavigationLink to={HELP_MANAGE_PROFILE_EDIT_PROFILE_PATH} label={t("menu.helpSubMenu.editProfileFeatures")} /></li>
                    </ul>
                </li>
                <li>
                    <a href="#" onClick={(e) => handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.settings, e)}>
                        {t("menu.helpSubMenu.settings")}
                        <i>
                        {getSidemenuArrow(HELP_PAGE_SIDEMENU_TYPES.settings)}
                        </i>
                    </a>
                    <ul className={`nav-inside ${isActiveMenu(HELP_PAGE_SIDEMENU_TYPES.settings) ? 'show' : ''}`}>
                        <li><NavigationLink to={HELP_SETTINGS_UPDATE_ACCOUNT} label={t("menu.helpSubMenu.updateAccount")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_INTEREST} label={t("menu.helpSubMenu.interest")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_NOTIFICATION} label={t("menu.helpSubMenu.notification")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_LANGUAGE} label={t("menu.helpSubMenu.language")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_LINK_ACCOUNT} label={t("menu.helpSubMenu.linkAccount")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_INVITE_FRIENDS} label={t("menu.helpSubMenu.inviteFriends")} /></li>
                        <li><NavigationLink to={HELP_SETTINGS_LOGOUT} label={t("menu.helpSubMenu.logout")} /></li>
                    </ul>
                </li>
                <li>
                    <a href="#" onClick={(e) => handleSetActiveSidemenu(HELP_PAGE_SIDEMENU_TYPES.featuresCommunicate, e)}>
                        {t("menu.helpSubMenu.featuresToCommunicate")}
                        <i>{getSidemenuArrow(HELP_PAGE_SIDEMENU_TYPES.featuresCommunicate)}</i>
                    </a>
                    <ul className={`nav-inside ${isActiveMenu(HELP_PAGE_SIDEMENU_TYPES.featuresCommunicate) ? 'show' : ''}`}>
                        <li><NavigationLink to={HELP_COMMUNICATE_CREATE_SHARE_POST} label={t("menu.helpSubMenu.createAndSharePost")} /></li>
                        <li><NavigationLink to={HELP_COMMUNICATE_EXPLORE_VIEW_POST} label={t("menu.helpSubMenu.exploreAndViewPost")} /></li>
                        <li><NavigationLink to={HELP_COMMUNICATE_CHAT_CALLS} label={t("menu.helpSubMenu.chatsAndCalls")} /></li>
                    </ul>
                </li>
            </ul>

        </>
    )
}

export default HelpSidebar;