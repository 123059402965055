import SelectLanguage from "Website/Language/Components/SelectLanguage";

const HeaderRight = (props) => {
    return (
        <ul className={`header_nav ${props.deviceType || ""}`}>
            <li>
                <SelectLanguage handleSetLanguage={props.handleSetLanguage} selectedLang={props.selectedLang} />
            </li>
            <li><a href={`${process.env.REACT_APP_ANDROID_APP_URL}`} target="_blank">Android</a></li>
            <li><a href={`${process.env.REACT_APP_IOS_APP_URL}`} target="_blank">iOS</a></li>
        </ul>
    )
}

export default HeaderRight;